import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import $ from "jquery"
import Metatags from "../../components/metatags"
import Navbar from "../../components/navbar"
import Gallery from "../../components/projects-gallery"
import MobGallery from "../../components/mob-gallery-comp"
import Footer from "../../components/footer"

const ApartamentoPalla = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  return (
    <>
      <Metatags
        title="daucodesigns | proyecto - apartamento palla"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <Navbar />
      <div className="height40" />
      <div className="height20" />
      <div id="project-header">
      <Container style={{ maxWidth: "1300px" }}>
        <Row>
          <Col>
            <div className="text-center projects">
              <h4 className="marcas-title nuestras-marcas-title">
                apartamento palla
              </h4>
              <h5>barcelona</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="height40" />
      </div>
      {windowWidth >= 768 ? (
        <Gallery
          imagesArray={data.projectImages.nodes}
          company="apartamento palla"
        />
      ) : (
        <MobGallery
          imagesArray={data.projectImages.nodes}
          ratio={765 / 1460}
          company="apartamento palla"
          windowWidth={windowWidth}
          leftArrow={data.leftArrow}
          rightArrow={data.rightArrow}
        />
      )}
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default ApartamentoPalla

export const query = graphql`
  {
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projectImages: allFile(
      filter: { relativePath: { regex: "/projects/apartamento/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  }
`
